export const isElement = (element: unknown): element is HTMLElement =>
  typeof element === 'object' && element instanceof HTMLElement

export const isObject = (object: unknown): object is object =>
  typeof object === 'object' && !Array.isArray(object) && object !== null

export const isString = (str: unknown): str is string => typeof str === 'string'

export const isUndefined = (val: unknown): val is undefined => typeof val === 'undefined'

export const isVideoHtmlElement = (el: HTMLElement): el is HTMLVideoElement =>
  'play' in el && 'poster' in el

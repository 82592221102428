import { AbstractVideoProtocolController } from '../../../types/abstracts'
import {
  IPlayable,
  PlayerConfig,
  StreamTypes,
  VideoProtocolConfiguration,
  VideoProtocolEvents
} from '../../../types'
import { isVideoHtmlElement } from '../../../utils'
import { isHlsController } from '../../../packages/HLS/helpers'

const getMediaProtocol = async (type: StreamTypes) => {
  switch (type) {
    case StreamTypes.HLS:
      return (await import('../../../packages/HLS')).Controller
    case StreamTypes.SLDP:
      return (await import('../../../packages/SLDP')).Controller
    default:
      return undefined
  }
}

export default class VideoBridge implements IPlayable {
  videoProtocolController: AbstractVideoProtocolController<StreamTypes> | undefined
  options: PlayerConfig | undefined
  public on: AbstractVideoProtocolController<StreamTypes>['subscribe'] | undefined

  private mutationObserver: MutationObserver | undefined
  private MOUNTED_CONTAINER_SELECTOR = '.single-spa-parcel-container'
  private MUTATION_SETTINGS = {
    subtree: true,
    childList: true
  }

  async init(element: HTMLVideoElement, options: PlayerConfig) {
    if (!isVideoHtmlElement(element)) {
      throw new Error('VideoProtocolBridge works with only HtmlVideoElement element')
    }
    const Controller = await getMediaProtocol(options.stream.type)
    if (!Controller) throw new Error('Can not import VideoProtocol Controller module')

    this.options = options

    this.videoProtocolController = new Controller({ ...options, element })
    await this.videoProtocolController.init()
    const highestParent = element?.closest(this.MOUNTED_CONTAINER_SELECTOR)?.parentElement
    if (!highestParent) {
      console.warn('Cannot find parent Node where library mounted')
      return
    }

    this.on = this.videoProtocolController.subscribe.bind(this.videoProtocolController)
    this.mutationObserver = this.foolProofDestroy(element, highestParent)
  }

  private foolProofDestroy(el: HTMLVideoElement, highestParent: HTMLElement | null) {
    if (!highestParent) return

    const mutationObserver = new MutationObserver(() => {
      if (!highestParent.querySelector('video')) {
        this.destroy()
        el.remove()
      }
    })

    mutationObserver.observe(highestParent, this.MUTATION_SETTINGS)
    return mutationObserver
  }

  get volume() {
    return this.videoProtocolController?.volume || 0
  }

  get muted() {
    return Boolean(this.videoProtocolController?.muted)
  }

  get playing(): boolean {
    return Boolean(this.videoProtocolController?.playing)
  }

  get currentTime() {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('currentTime: SLDP protocol works only for live playing')
      return
    }

    return this.videoProtocolController.currentTime
  }

  private increaseVolume(value: number) {
    this.videoProtocolController?.increaseVolume(value)
  }

  private decreaseVolume(value: number) {
    this.videoProtocolController?.decreaseVolume(value)
  }

  reInitProtocol(
    options: Omit<VideoProtocolConfiguration, 'element'> &
      Partial<VideoProtocolEvents<StreamTypes.HLS>>
  ) {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('reInitProtocol: Method works only in HLS stream type')
      return
    }

    this.videoProtocolController.reInit(options)
  }

  setCurrentTime(value: number) {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('setCurrentTime: SLDP protocol works only for live playing')
      return
    }

    return this.videoProtocolController.setCurrentTime(value)
  }

  startLoadFrom(seconds: number) {
    if (!isHlsController(this.videoProtocolController)) {
      console.error(
        'startLoadFrom: SLDP protocol works only for live playing. It can not be loading from interval'
      )
      return
    }

    this.videoProtocolController.startLoad(seconds)
  }

  stopLoad() {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('startLoadFrom: Method works only in HLS stream type')
      return
    }

    this.videoProtocolController.stopLoad()
  }

  setCurrentTimeOnLoadLevel(number: number) {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('setCurrentTime: Method works only in HLS stream type')
      return
    }

    if (!this.videoProtocolController.playerInstance) return
    this.videoProtocolController.playerInstance.currentTime = number
    this.startLoadFrom(number)
    this.play()
  }

  playBackRate(value: number) {
    if (!isHlsController(this.videoProtocolController)) {
      console.error('playBackRate: Change player speed works only in HLS protocol')
      return
    }

    this.videoProtocolController.setSpeed(value)
  }

  toggleFullscreen(flag?: boolean) {
    this.videoProtocolController?.toggleFullScreen(flag)
  }

  setVolume(value: number) {
    value > 0 ? this.increaseVolume(value) : this.decreaseVolume(value)
  }

  setMuted(flag: boolean) {
    this.videoProtocolController?.setMuted(flag)
  }

  play() {
    this.videoProtocolController?.play()
  }

  stop() {
    this.videoProtocolController?.stop()
  }

  destroy() {
    this.mutationObserver?.disconnect()
    this.videoProtocolController?.destroy()
  }
}

import { PlayerConfig } from '../../../types'
import { isElement, isObject, isString } from '../../../utils'


export function assertIsPlayerConfig(config?: unknown): asserts config is PlayerConfig {
  if (!config || !isObject(config)) {
    throw new Error('Config is not defined')
  }

  if (!('stream' in config)) {
    throw new Error('config or configs.stream is require params')
  }

  const { stream } = config

  if (!isObject(stream)) {
    throw new Error('Stream must be object')
  }

  if (!('type' in stream) || !('url' in stream)) {
    throw new Error('config or configs.stream is require params')
  }

  const { type, url } = stream

  if (!isString(type) && !isString(url)) {
    throw new Error('url stream type is require params')
  }
}

export function assertIsHtmlVideoElement(element?: unknown): asserts element is HTMLVideoElement {
  if (!isElement(element)) {
    throw new Error('Element must be HtmlVideoElement')
  }
}

/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { FC, useCallback, useState } from 'react'
import { DateSelector } from './DateSelector'
import { useStore } from '../../../hooks/useStore'
import { Refresh } from '../../ui/buttons/Refresh'
import { Backward } from '../../ui/buttons/Backward'
import { Forward } from '../../ui/buttons/Forward'
import { Play } from '../../ui/buttons/Play'
import { EnterFullscreen } from '../../ui/buttons/EnterFullscreen'
import { Pause } from '../../ui/buttons/Pause'
import { observer } from 'mobx-react-lite'
import { ExitFullscreen } from '../../ui/buttons/ExitFullscreen'
import { ScaleControl } from '../ScaleControl'
import { Select } from '../../ui/buttons/Select'
import { SpeedControl } from './SpeedControl'
import { VolumeControl } from './VolumeControl'
import { useLocale } from '../../../hooks/useLocale'
import { SaveButton } from '../SaveButton'
import { rem } from '../../../utils/rem'
import { useWidgetProps } from '../../../Root'
import { E2EModule } from '../../../__test__'
import { Button } from 'primereact/button'
import { isStream } from '../../../utils/basicValidators'
import { Dropdown } from 'primereact/dropdown'
import { useCameraTimeOffset } from '../../../hooks'
import { convertTimeToLocaleString } from '../../../utils/convertTimeToLocaleString'

const getStepAmount = (v: unknown): number =>
  typeof v === 'number' && !isNaN(v) && v > 0 ? Math.floor(v) : 10

export const Panel: FC<{ archiveEntry: IArchiveEntry }> = observer(({ archiveEntry }) => {
  const widgetProps = useWidgetProps()
  const store = useStore()
  const { buttonLabels } = useLocale().panelLocale
  const [forwardAmount] = useState(() => getStepAmount(widgetProps?.props?.forwardAmount))

  const onBackward = useCallback(
    () => store.setCurrentTime(store.currentTime - forwardAmount * 1000),
    [forwardAmount, store],
  )
  const onForward = useCallback(
    () => store.setCurrentTime(store.currentTime + forwardAmount * 1000),
    [forwardAmount, store],
  )

  const toggleSelect = useCallback(() => store.setSelectionMode(!store.selectionMode), [store])
  const toggleMuted = useCallback(() => store.setIsMuted(!store.isMuted), [store])

  const canSwitchStreams = isStream(archiveEntry.entity) && archiveEntry?.streams

  return (
    <div
      css={s.panel}
      className={`${store.isWaiting ? 'loading' : ''}`}
      data-compact={store.width < rem(1280)}
      data-fullscreen={store.fullScreen}>
      <section data-cy={E2EModule.attributes.datePicker} css={s.alignLeft}>
        {store.isDateTape && (
          <DateSelector archiveEntry={archiveEntry} compact={store.width < rem(1280)} />
        )}
      </section>
      <section>
        <Refresh
          data-cy={E2EModule.attributes.updateButton}
          tooltipActive
          tooltip={buttonLabels.refresh}
          onClick={archiveEntry.refresh}
        />
        <Backward
          data-cy={E2EModule.attributes.tenSecSwipeBackButton}
          tooltipActive
          tooltip={buttonLabels.backward.replace('{{}}', forwardAmount.toString())}
          onClick={onBackward}
        />
        {store.isPlaying ? (
          <Pause
            data-cy={E2EModule.attributes.pauseButton}
            tooltipActive
            tooltip={buttonLabels.pause}
            onClick={store.pause}
          />
        ) : (
          <Play
            data-cy={E2EModule.attributes.playButton}
            tooltipActive
            tooltip={buttonLabels.play}
            onClick={store.play}
          />
        )}
        <Forward
          data-cy={E2EModule.attributes.tenSecSwipeNextButton}
          tooltipActive
          tooltip={buttonLabels.forward.replace('{{}}', forwardAmount.toString())}
          onClick={onForward}
        />
        <SpeedControl value={store.speed} onChange={store.setSpeed} />
      </section>
      {store.isExportable && (
        <section css={s.margin15}>
          <Select
            data-cy={E2EModule.attributes.selectFragment}
            data-active={store.selectionMode}
            tooltipActive
            tooltip={store.selectionMode ? buttonLabels.removeSelect : buttonLabels.select}
            onClick={toggleSelect}
          />
          <SaveButton saveRecord={archiveEntry.saveRecord} />
        </section>
      )}
      <Screenshot />
      {canSwitchStreams && <SelectStreams archiveEntry={archiveEntry} />}
      <section css={s.alignRight}>
        <ScaleControl compact={store.width < rem(1024)} />
        <VolumeControl
          muted={store.isMuted}
          toggleMuted={toggleMuted}
          value={store.volume}
          onChange={store.setVolume}
        />
        {store.fullScreen ? (
          <ExitFullscreen
            tooltipActive
            tooltipAlign={'right'}
            data-cy={E2EModule.attributes.fullScreenExitButton}
            tooltip={buttonLabels.exitFullscreen}
            onClick={() => store.exitFullScreen('user')}
          />
        ) : (
          <EnterFullscreen
            tooltipActive
            data-cy={E2EModule.attributes.fullScreenButton}
            tooltipAlign={'right'}
            tooltip={buttonLabels.enterFullscreen}
            onClick={() => store.enterFullScreen('user')}
          />
        )}
      </section>
    </div>
  )
})

const SelectStreams = ({ archiveEntry }: { archiveEntry: IArchiveEntry }) => {
  const streams = archiveEntry?.streams || []

  const onStreamChange = (e: { value: string }) => {
    const newStreamId = e.value;
    const newStream = streams.find(stream => stream.id === newStreamId);
    if (newStream) {
      archiveEntry?.setActiveStream && archiveEntry.setActiveStream(newStream);
    }
  };
  return (
    <div>
      <Dropdown
        value={archiveEntry.entity.id}
        options={streams}
        optionLabel="title"
        optionValue="id"
        onChange={onStreamChange}
        className="p-dropdown p-dropdown-sm p-button-secondary"
        panelStyle={{ margin: '-5px 0' }}
      />
    </div>
  )
}

const getSnapshot = (videoNode: HTMLVideoElement, width = 1920, height = 1080, currentTime: number , cameraTimeOffset: number | null) => {
  let canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx?.drawImage( videoNode, 0, 0, canvas.width, canvas.height );


  // Draw time in screenshot
  if (cameraTimeOffset !== null) {
    ctx.font = '48px Arial';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'top';
    ctx.fillStyle = 'white';
    const text = convertTimeToLocaleString(currentTime - cameraTimeOffset, 'ru-RU');
    const textWidth = ctx.measureText(text).width;
    const x = canvas.width - textWidth + 300;
    const y = 50;
    ctx.fillText(text, x, y);
  }

  let image = canvas.toDataURL('image/png');
  const link = document.createElement('a')
  link.download = 'screenshot.png'
  link.href = image
  link.click()
}

const Screenshot: FC = observer(() => {
  const locale = useLocale()
  const store = useStore()
  const [loadingStatus, setLoadingStatus] =
    useState<'init' | 'loading' | 'error' | 'complete'>('init')
    const {cameraTimeOffset} = useCameraTimeOffset();

  // У пользователя с отсутствием прав, streamId - пустая строка
  const isBlockedSelectRange = !store.currentRange?.streamId && store.isEnableLockingFeature
  const handleCapture = useCallback(async () => {
    // @ts-ignore
    const videoNode = store?.videoRef?.current
    if (!videoNode) return

    try {
      setLoadingStatus('loading')
      getSnapshot(videoNode, store.naturalWidth, store.naturalHeight, store.currentTime, cameraTimeOffset)
      setLoadingStatus('complete')

    } catch (error) {
      setLoadingStatus('error')
    }
  }, [store, cameraTimeOffset])
  return (
    <Button
      css={s.button}
      disabled={isBlockedSelectRange}
      icon={loadingStatus === 'loading' ? 'mdi mdi-loading mdi-spin' : ''}
      label={locale.panelLocale.saveScreenshot}
      className="p-button-outlined p-button-sm"
      onClick={handleCapture}
    />
  )
})

// language=SCSS
const s = {
  panel: css`
    & {
      position: relative;
      z-index: 1;

      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: calc(45rem / var(--bfs)) calc(30rem / var(--bfs));

      background: var(--surface-a);

      &[data-compact='true'] {
        padding: calc(30rem / var(--bfs)) calc(20rem / var(--bfs));
      }

      &[data-fullscreen='true'] {
        padding: calc(10rem / var(--bfs)) calc(20rem / var(--bfs));
      }

      > section {
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
          margin-right: calc(30rem / var(--bfs));
        }
        > *:last-child {
          margin-right: 0;
        }
      }
    }
  `,
  alignLeft: css`
    & {
      justify-content: flex-start !important;
    }
  `,
  alignRight: css`
    & {
      justify-content: flex-end !important;
    }
  `,
  margin15: css`
    > * {
      margin-right: calc(15rem / var(--bfs)) !important;
    }
    > *:last-child {
      margin-right: 0 !important;
    }
  `,
  button: css`
    max-width: calc(150rem / var(--bfs));
  `,
}

import { IPlayable, MediaType, PlayerConfig } from '../../types'
import { assertIsHtmlVideoElement, assertIsPlayerConfig } from './helpers'
import { VideoBridge } from './Bridges'

const getMediaBridge = async (type: MediaType) => {
  switch (type) {
    case MediaType.VIDEO:
      return VideoBridge
    default:
      return undefined
  }
}

type IPlayableBridge<T extends IPlayable> = T
type MediaBridge<T extends MediaType> = T extends MediaType.VIDEO
  ? IPlayableBridge<VideoBridge>
  : never

export class Player {
  mediaType: MediaType
  mediaBridge: MediaBridge<typeof this.mediaType> | undefined

  constructor(element?: HTMLVideoElement, config?: PlayerConfig) {
    assertIsPlayerConfig(config)
    assertIsHtmlVideoElement(element)

    this.mediaType = MediaType.VIDEO
    this.init(element, config)
  }

  async init(element: HTMLVideoElement, config: PlayerConfig) {
    const Bridge = await getMediaBridge(this.mediaType)
    if (!Bridge) throw new Error(`Can not get MediaBridge by type - ${this.mediaType}`)
    this.mediaBridge = new Bridge()
    await this.mediaBridge.init(element, config)
  }

  get bridgeApi() {
    return this.mediaBridge
  }

  get volume() {
    return this.mediaBridge?.volume
  }

  get muted() {
    return this.mediaBridge?.muted
  }

  setVolume(volume: number) {
    this.mediaBridge?.setVolume(volume)
  }

  setMuted(flag: boolean) {
    this.mediaBridge?.setMuted(flag)
  }

  public toggleFullScreen(flag?: boolean) {
    this.mediaBridge?.toggleFullscreen(flag)
  }

  public play() {
    this.mediaBridge?.play()
  }

  public stop() {
    this.mediaBridge?.stop()
  }

  public destroy() {
    this.mediaBridge?.destroy()
  }
}

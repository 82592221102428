/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IStore } from '../../StoreModel'
import { useCameraTimeOffset } from '../../hooks'
import { convertTimeToLocaleString } from '../../utils/convertTimeToLocaleString'

const calculateAspectRatio = (width: number, height: number) => {
  const gcd = gcdCalculator(width, height)
  return `${Math.floor(width / gcd)} / ${Math.floor(height / gcd)}`
}

const gcdCalculator = (a: number, b: number): number => {
  if (b === 0) {
    return a
  }
  return gcdCalculator(b, a % b)
}

export const Timestamp: FC<{ store: IStore }> = observer(({ store }) => {
  const [aspectRatio, setAspectRatio] = useState('16 / 9')
  const [currentTimeLocal, setCurrentTimeLocal] = useState('')
  const { cameraTimeOffset } = useCameraTimeOffset()

  const { naturalWidth, naturalHeight, currentTime } = store

  useLayoutEffect(() => {
    const ratio = calculateAspectRatio(naturalWidth, naturalHeight)
    setAspectRatio(ratio)
  }, [naturalWidth, naturalHeight])

  useEffect(() => {
    if (!cameraTimeOffset) return

    // TODO: Заменить ru-RU на локаль из Intl.locale
    setCurrentTimeLocal(convertTimeToLocaleString(currentTime - cameraTimeOffset, 'ru-RU'))
  }, [cameraTimeOffset, currentTime])

  return (
    <div css={styles} style={{ aspectRatio }}>
      <span style={{ fontFamily: 'sans-serif' }}>{currentTimeLocal}</span>
    </div>
  )
})

const styles = css`
  position: absolute;
  font-size: 2rem;
  padding: 2rem 5rem;
  height: fit-content;
  max-height: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: #fff;
  text-shadow: 3px 3px 0 #000, -3px 3px 0 #000, -3px -3px 0 #000, 3px -3px 0 #000;
`

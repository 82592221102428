/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {FC, useEffect, useLayoutEffect, useState} from 'react';
import {IStore} from '../../IStore';
import {useCameraTimeOffset} from '../../hooks';
import {convertTimeToLocaleString} from '../../utils/convertTimeToLocaleString';

const calculateAspectRatio = (width: number, height: number) => {
  const gcd = gcdCalculator(width, height);
  return `${Math.floor(width / gcd)} / ${Math.floor(height / gcd)}`;
};

const gcdCalculator = (a: number, b: number): number => {
  if (b === 0) {
    return a;
  }
  return gcdCalculator(b, a % b);
};

export const Timestamp: FC<{store: IStore; isPreview?: boolean}> = ({store, isPreview}) => {
  const [aspectRatio, setAspectRatio] = useState('16 / 9');
  const [currentTime, setCurrentTime] = useState('');
  const {cameraTimeOffset} = useCameraTimeOffset();

  const {isFullscreen, naturalSize} = store;

  useLayoutEffect(() => {
    const ratio = calculateAspectRatio(naturalSize.width, naturalSize.height);
    setAspectRatio(ratio);
  }, [naturalSize.width, naturalSize.height]);

  useEffect(() => {
    if (!cameraTimeOffset) return;

    if (isPreview) {
      // TODO: Заменить ru-RU на локаль из Intl.locale
      setCurrentTime(convertTimeToLocaleString(Date.now() - cameraTimeOffset, 'ru-RU'));
      return;
    }

    const interval = setInterval(() => {
      // TODO: Заменить ru-RU на локаль из Intl.locale
      setCurrentTime(convertTimeToLocaleString(Date.now() - cameraTimeOffset, 'ru-RU'));
    }, 1000);

    return () => clearInterval(interval);
  }, [cameraTimeOffset, isPreview]);

  return (
    <div css={styles} className={`${isFullscreen ? 'full' : ''}`} style={{aspectRatio}}>
      <span style={{fontFamily: 'sans-serif'}}>{currentTime}</span>
    </div>
  );
};

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 1rem;
  margin: auto;
  height: fit-content;
  max-height: 100%;
  color: #fff;
  text-shadow: 3px 3px 0 #000, -3px 3px 0 #000, -3px -3px 0 #000, 3px -3px 0 #000;
  z-index: 1;
  &.full {
    font-size: 2rem;
    padding: 2rem;
  }
`;

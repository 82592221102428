/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {reaction} from 'mobx';
import {observer} from 'mobx-react-lite';
import {destroy, isAlive, onAction} from 'mobx-state-tree';

import {ZoomZone} from '@netvision/front-utils/lib/react/components/ZoomZone';
import {isFunction, isNull, isUndefined} from 'lodash-es';

import {createStore, IStore} from '../../IStore';

import {createDisposers} from '../../utils/disposers';
import {genId} from '../../utils/genId';

import {
  useStreamAdapter,
  useGetActiveStream,
  useSetActiveStream,
  useHideControls,
  useCameraLockedData,
  useWidgetProps,
  useGetUserInfo
} from '../../hooks';
import {StreamStatuses} from '../../hooks/useSubStream';

import {
  gradientCss,
  mainContainer,
  overlayCss,
  panelPositionCss,
  relativeStyle,
  visibleCss
} from '../../components/common';

import {ControlPanel, IAvailableControl, Spinner, SpinnerWrapper, PlayingGroup, Timestamp} from '../../components/';

import {initPlyr} from '../../components/initPlyr';
import {PTZBlocked, PTZConnect, PTZUnLock} from '../../modals';
import {usePresets} from '../../components/ptzControls/hooks/usePresets';
import {SensitivityProvider} from '../../components/ptzControls/hooks/useSensitivity';
import {ArrowBack} from '../../components/control-panel/controls/buttons';

import {MountHooks, OverlayParams} from '../../IWidgetProps';

import {countActiveAnalyticsByCamera} from '../../analyticsChecks';
import {CreatePTZPreview, PtzControlsAdapter, UpdatePTZPreview} from './components';
import {ActivatePtzButton} from './components/active-ptz-button';

export interface ILiveProps {
  cameraId: string;
  ratio: 'fit' | string | null | undefined;
  overlayMountHooks?: MountHooks<OverlayParams>;
  onStop: () => void;
  onStalled: () => void;
  stalledTimeout?: number;
  disablePtz: boolean;
  streamType?: string;
}

export const Live: FC<ILiveProps> = observer(
  ({cameraId, ratio, onStalled, onStop, stalledTimeout, disablePtz, streamType}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const videoRootRef = useRef<HTMLDivElement | null>(null);
    const userInfo = useGetUserInfo();
    const [video, setVideo] = useState<HTMLVideoElement | null>(null);
    const [controls, setControls] = useState<IAvailableControl[]>(['blockCamera']);
    const [store, setStore] = useState<IStore | null>(null);

    const [isPtzControlsDisabled, setPtzControlsDisabled] = useState(true);
    const [showPtzConnectionError, setShowPtzConnectionError] = useState(false);
    const [showConfirmPtz, setShowConfirmPtz] = useState(0);
    const [showPTZBlocked, setShowPTZBlocked] = useState(false);

    const {activeStream, fetchStreams} = useGetActiveStream();
    const {updateStreamStatus} = useSetActiveStream();
    const {updatePresetImageOn} = useWidgetProps();
    const controlsVisible = useHideControls(containerRef);
    const [loading, presets, refreshPresets] = usePresets();
    const streamAdapter = useStreamAdapter(streamType);
    const {getter: lockingInfo} = useCameraLockedData();
    const {canI, isLoading: cameraBlockedLoading, is, lockingData} = lockingInfo;
    const {initiallyShowPtz} = useWidgetProps();

    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
      if (!cameraId) return;
      const store = createStore();
      store.setIsWaiting(true);
      setStore(store);

      return () => destroy(store);
    }, [cameraId]);

    useEffect(() => {
      if (!updatePresetImageOn) return
      if (store?.isPtzSupported !== false) return
      refreshPresets()
    }, [store?.isPtzSupported])

    useEffect(() => {
      if (cameraBlockedLoading || !store) return;
      if (is.streamLockedNow && !canI.watchLockedStreams) onStop();
      if (is.controlsLockedNow && !is.streamLockedNow) store.setIsBlockedControls(true && !canI.changeLockedControls);
    }, [
      cameraBlockedLoading,
      canI.changeLockedControls,
      canI.watchLockedStreams,
      is.controlsLockedNow,
      is.streamLockedNow,
      onStop,
      store
    ]);

    useEffect(() => {
      if (!lockingData?.status) return;
      if (!['Finished', 'Completion', 'Creation'].includes(lockingData.status)) return;
      fetchStreams();
    }, [fetchStreams, lockingData?.status]);

    useLayoutEffect(() => {
      if (isUndefined(activeStream)) return;
      const videoRoot = videoRootRef.current;
      const container = containerRef.current;
      if (!streamAdapter || !store || !videoRoot || !container || cameraBlockedLoading || !userInfo) return;

      if (isNull(activeStream)) {
        onStop();
        return;
      }

      const d = createDisposers();
      if (isFunction(onStop)) {
        d.add(
          onAction(store, (action) => {
            const {name, args} = action;
            if (name !== 'setIsPlaying') return;
            const [value, source] = args as [boolean, string];
            if (source !== 'user' || value) return;
            onStop();
          })
        );
      }

      const controls: IAvailableControl[] = ['play', 'volume', 'fullscreen', 'streams', 'screenshot', 'blockCamera'];
      const videoEl = document.createElement('video');
      videoRef.current = videoEl;
      videoRoot.append(videoEl);
      videoEl.id = genId();
      videoEl.autoplay = true;
      videoEl.muted = true;
      const plyr = initPlyr(videoEl, container, ratio, store, controls);
      const lastMutedValue = plyr.muted;

      d.add(() => (videoRoot.innerHTML = ''));
      try {
        const userId = localStorage.getItem('netvision:user-id');
        const url = new URL(activeStream.wsStreamUrl);
        url.searchParams.append('subjectTitle', userInfo.fullName);
        url.searchParams.append('subjectId', userId!);

        d.add(
          streamAdapter.init(
            {
              streamUrl: url.toString(),
              videoEl,
              onStop,
              onError: (errorType) => {
                console.error(`Stream not available, error - ${errorType}`);
                updateStreamStatus(activeStream.id, StreamStatuses.error);
                onStop();
              }
            },
            () => {
              plyr.destroy();
            }
          )
        );

        // set same value as before
        plyr.muted = lastMutedValue;
        setVideo(videoEl);
        setControls(controls);
      } catch (e) {
        console.error(e);
      }

      return d.flush;
    }, [
      activeStream,
      cameraBlockedLoading,
      cameraId,
      onStop,
      ratio,
      store,
      streamAdapter,
      updateStreamStatus,
      userInfo
    ]);

    useEffect(() => {
      if (!store) return;
      if (!isFunction(onStalled)) return;

      const d = createDisposers();
      let id: any = 0;

      d.add(
        reaction(
          () => store.isWaiting,
          (isWaiting) => {
            clearTimeout(id);
            if (!isWaiting) return;
            id = setTimeout(() => onStalled(), stalledTimeout);
          }
        )
      );

      d.add(() => clearTimeout(id));
      return d.flush;
    }, [store, onStalled, stalledTimeout]);

    useEffect(() => {
      if (store?.isFullscreen) return;
      setShowConfirmPtz(0);
    }, [store?.isFullscreen]);

    const onPtzControllerMounted = useCallback(() => {
      if (!initiallyShowPtz || store?.isDisablePtz) return;
      countActiveAnalyticsByCamera(cameraId).then((n) => {
        if (store?.isDisablePtz) return;
        if (n <= 0) return setPtzControlsDisabled(false);
        setShowConfirmPtz(n);
      });
    }, [cameraId, initiallyShowPtz, store?.isDisablePtz]);

    const onHidePtzUnlock = useCallback(
      (result: boolean) => {
        if (!store || !isAlive(store)) return;
        setShowConfirmPtz(0);
        store.setIsPtzVisible(result);
        setPtzControlsDisabled(!result);
      },
      [store]
    );

    const hasControls = controls.length > 0;
    const canCreatePtzPreview =
      !loading && updatePresetImageOn && store?.isPtzSupported === false && presets.length === 0;
    const canUpdatePtzPreview = !loading && updatePresetImageOn && store?.isPtzSupported === false && presets.length > 0

    return (
      <div
        ref={containerRef}
        css={mainContainer}
        style={relativeStyle}
        className={`main-container ${!video ? 'loading' : ''}`}>
        {!video && <SpinnerWrapper />}
        <div ref={videoRootRef} />
        {!isNull(store) && (
          <Fragment>
            {controlsVisible && store.isFullscreen && (
              <ArrowBack onClick={() => store?.setIsFullscreen(false, 'user')} />
            )}
            <PlayingGroup store={store} />
            {!loading && video && store && <Timestamp store={store} />}
            {hasControls && <div css={[gradientCss, visibleCss]} data-visible={controlsVisible} />}
            <div css={overlayCss}>
              {!disablePtz ? (
                <SensitivityProvider>
                  <PtzControlsAdapter
                    setIsPtzConnectionError={setShowPtzConnectionError}
                    ptzControllerDisable={isPtzControlsDisabled}
                    onControlsMounted={onPtzControllerMounted}
                    cameraId={cameraId}
                    store={store}>
                    {video && <ZoomZone elements={[video]} />}
                  </PtzControlsAdapter>
                </SensitivityProvider>
              ) : (
                <div>{video && <ZoomZone elements={[video]} />}</div>
              )}
            </div>
            <Spinner store={store} />
            {hasControls && (
              <ControlPanel
                store={store}
                controls={controls}
                css={[panelPositionCss, visibleCss]}
                videoRef={videoRef}
                data-visible={controlsVisible}>
                {!disablePtz && (
                  <ActivatePtzButton
                    store={store}
                    cameraId={cameraId}
                    setPtzControlsDisabled={setPtzControlsDisabled}
                    setShowPtzConnectionError={setShowPtzConnectionError}
                    setShowPTZBlocked={setShowPTZBlocked}
                    onConfirmRequired={setShowConfirmPtz}
                  />
                )}
                {canCreatePtzPreview && <CreatePTZPreview />}
                {canUpdatePtzPreview && <UpdatePTZPreview presets={presets} />}
              </ControlPanel>
            )}
            {Boolean(showConfirmPtz) && <PTZUnLock count={showConfirmPtz} onHide={onHidePtzUnlock} />}
            {showPtzConnectionError && <PTZConnect onHide={() => setShowPtzConnectionError(false)} />}
            {showPTZBlocked && <PTZBlocked onHide={() => setShowPTZBlocked(false)} />}
          </Fragment>
        )}
      </div>
    );
  }
);

import './prepare';
import {startApplication} from './startApp';
import {addThemes} from './addThemes';
import {addLocales} from './addLocales';
import {addLocking} from './addLocking';

fetch('/sitedata.json')
  .then((res) => res.json())
  .then((siteData) => {
    addThemes(siteData);
    addLocales(siteData);
    addLocking();
    startApplication(siteData.widgetTree);
  });

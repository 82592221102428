/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import React, {FC, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Button} from 'primereact/button';
import {OverlayPanel} from 'primereact/overlaypanel';

import {useGetActiveStream, useSetActiveStream, useLocale, useWidgetProps, useCameraTimeOffset} from '../../hooks';
import {LockButtonAdapter} from '../../widgets-adapter';

import {e2eModule} from '../../__test__';
import {IStore} from '../../IStore';

import {VolumeControl, Play, EnterFullscreen, ExitFullscreen, Pause, StreamButton} from './controls';
import {convertTimeToLocaleString} from '../../utils/convertTimeToLocaleString';
export type IAvailableControl = 'play' | 'streams' | 'volume' | 'fullscreen' | 'screenshot' | 'blockCamera';

export type ControlPanelProps = {
  store: IStore;
  controls: IAvailableControl[];
  head?: ReactNode;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
} & JSX.IntrinsicElements['div'];

export const ControlPanel: FC<ControlPanelProps> = ({store, controls, children, head, videoRef, ...rest}) => {
  const controlsSet = new Set(controls);
  const onlyHasBlockCamera = (controlsSet.size === 1 && controlsSet.has('blockCamera')) || store?.isBlockedStream;
  return (
    <div
      {...rest}
      className={`control-panel ${rest.className} ${onlyHasBlockCamera ? 'hidden' : ''}`}
      css={panelCss}
      data-cy={e2eModule.E2E_ATTRIBUTES.controlPanel}>
      <div data-pos="left">
        {controlsSet.has('play') && <PlayPause store={store} />}
        {head}
      </div>
      <div data-pos={'right'}>
        {children}
        <div />
        {controlsSet.has('screenshot') && videoRef && <Screenshot videoRef={videoRef} store={store} />}
        {controlsSet.has('blockCamera') && <LockButtonAdapter />}
        {controlsSet.has('streams') && <SelectStream store={store} videoRef={videoRef} />}
        {controlsSet.has('volume') && <Volume store={store} />}
        {controlsSet.has('fullscreen') && <FullScreen store={store} videoRef={videoRef} />}
      </div>
    </div>
  );
};

const panelCss = css`
  & {
    container-type: inline-size;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(15rem / var(--bfs));
    z-index: 10;

    .volume-wrapper {
      height: calc(32rem / var(--bfs));
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &.hidden[data-visible='true'] {
      opacity: 0;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & > div[data-pos='left'] > * {
    margin-right: calc(20rem / var(--bfs));
  }

  & > div[data-pos='left'] > *:last-child {
    margin-right: 0;
  }

  & > div[data-pos='right'] > * {
    margin-left: calc(20rem / var(--bfs));
  }

  @container (max-width: 300px) {
    padding: calc(15rem / var(--bfs)) calc(2rem / var(--bfs));
    & > div[data-pos='right'] > * {
      margin-left: calc(5rem / var(--bfs));
    }

    [data-role='button-fullscreen'],
    [data-role='button-pause'],
    [data-role='button-play'],
    [data-status='mute'],
    [data-status='unmute'],
    .button-block-camera.p-button.p-button-text.p-button-plain,
    .select-stream-button,
    .volume-wrapper {
      font-size: calc(24rem / var(--bfs));
      width: calc(26rem / var(--bfs));
      height: calc(26rem / var(--bfs));

      svg {
        width: calc(26rem / var(--bfs));
        height: calc(26rem / var(--bfs));
      }
    }
  }
`;

const PlayPause: FC<{store: IStore}> = observer(({store}) => {
  const locale = useLocale();
  const handlePlay = useCallback(() => {
    store.setIsPlaying(true, 'user');
  }, [store]);
  const handlePause = useCallback(() => {
    store.setIsPlaying(false, 'user');
  }, [store]);
  return store.isPlaying ? (
    <Pause
      data-cy={e2eModule.E2E_ATTRIBUTES.playPauseBtn}
      data-status="play"
      data-role={'button-pause'}
      tooltipAlign={'left'}
      tooltip={locale.controls.pause}
      onClick={handlePause}
    />
  ) : (
    <Play
      data-cy={e2eModule.E2E_ATTRIBUTES.playPauseBtn}
      data-status="pause"
      data-role={'button-play'}
      tooltipAlign={'left'}
      tooltip={locale.controls.play}
      onClick={handlePlay}
    />
  );
});

const Volume: FC<{store: IStore}> = observer(({store}) => {
  const {controls} = useLocale();
  const onChange = useCallback(
    (value) => {
      store.setVolume(value, 'user');
    },
    [store]
  );

  const toggleMute = useCallback(() => {
    store.setIsMuted(!store.isMuted, 'user');
  }, [store]);

  return (
    <VolumeControl
      muted={store.isMuted}
      toggleMute={toggleMute}
      tooltipAlign={'right'}
      tooltip={controls.volume}
      value={store.volume}
      onChange={onChange}
    />
  );
});

const FullScreen: FC<{store: IStore; videoRef?: React.MutableRefObject<HTMLVideoElement | null>}> = observer(
  ({store, videoRef}) => {
    const locale = useLocale();
    const {lastActiveStreamChangedByUser} = useGetActiveStream();
    const {setStreamByType} = useSetActiveStream();
    const {switchToCurrentStreamByTypeOnFullScreen, fullScreenOnDblclick} = useWidgetProps();

    useEffect(() => {
      const mainWrapper = videoRef?.current?.closest('.main-container');
      if (!mainWrapper || !fullScreenOnDblclick) return;
      const toggleFullScreen = () => store?.setIsFullscreen(!store.isFullscreen, 'user');
      mainWrapper.addEventListener('dblclick', toggleFullScreen);
      return () => mainWrapper.removeEventListener('dblclick', toggleFullScreen);
    }, [fullScreenOnDblclick, store, videoRef]);

    const handleEnter = useCallback(() => store.setIsFullscreen(true, 'user'), [store]);
    const handleExit = useCallback(() => store.setIsFullscreen(false, 'user'), [store]);

    useEffect(() => {
      if (!switchToCurrentStreamByTypeOnFullScreen || !store) return;
      const whichStreamType = store.isFullscreen
        ? switchToCurrentStreamByTypeOnFullScreen
        : lastActiveStreamChangedByUser.current?.streamType;

      setStreamByType(whichStreamType);
    }, [
      lastActiveStreamChangedByUser,
      setStreamByType,
      store,
      switchToCurrentStreamByTypeOnFullScreen,
      store.isFullscreen
    ]);

    return store.isFullscreen ? (
      <ExitFullscreen
        data-role={'button-fullscreen'}
        data-cy={e2eModule.E2E_ATTRIBUTES.exitFullScreen}
        tooltipAlign={'right'}
        tooltip={locale.controls.exitFullscreen}
        onClick={handleExit}
      />
    ) : (
      <EnterFullscreen
        data-role={'button-fullscreen'}
        data-cy={e2eModule.E2E_ATTRIBUTES.enterFullScreen}
        tooltipAlign={'right'}
        tooltip={locale.controls.enterFullscreen}
        onClick={handleEnter}
      />
    );
  }
);

const SelectStream: FC<{store: IStore; videoRef: ControlPanelProps['videoRef']}> = observer(({store, videoRef}) => {
  const [_, setForceUpdate] = useState(false);

  const {streams, activeStream} = useGetActiveStream();
  const {setStreamById} = useSetActiveStream();

  const overlayPanelRef = useRef<OverlayPanel>(null!);
  const layoutsElement = useRef<HTMLDivElement | null>(document.querySelector('#fullScreenAnchor'));

  // Раскладки
  const isLayoutsFullScreen = document.fullscreenElement === layoutsElement.current;

  useEffect(() => {
    const forceUpdate = () => {
      const canUpdate = document.fullscreenElement === layoutsElement.current || !document.fullscreenElement;
      canUpdate && setForceUpdate((prev) => !prev);
    };

    document.addEventListener('fullscreenchange', forceUpdate);
    return () => document.removeEventListener('fullscreenchange', forceUpdate);
  }, []);

  const appendWrapper = store.isFullscreen
    ? videoRef?.current?.closest('.main-container')
    : layoutsElement.current && isLayoutsFullScreen
    ? layoutsElement.current
    : document.body;

  return (
    <React.Fragment>
      <StreamButton
        data-cy={e2eModule.E2E_ATTRIBUTES.stream}
        className="select-stream-button"
        active
        css={$selectStream}
        onClick={(e) => overlayPanelRef.current.toggle(e)}
      />
      <OverlayPanel
        className="live-player-select-stream-panel"
        css={css`
          &.p-overlaypanel.live-player-select-stream-panel {
            background: transparent;
            width: auto;
            max-height: unset;
            overflow: unset;
          }

          .p-overlaypanel-content {
            padding: 0;
          }
        `}
        ref={overlayPanelRef}
        dismissable
        appendTo={appendWrapper}>
        <div className="p-d-flex p-flex-column p-jc-center p-ai-start">
          {streams?.map(({title, id}, index) => (
            <Button
              data-cy={`${e2eModule.E2E_ATTRIBUTES.stream}-${index}`}
              key={id}
              onClick={() => {
                setStreamById(id, true);
                overlayPanelRef.current.hide();
              }}
              className={`p-mb-1 ${activeStream?.id === id ? 'p-button-outlined' : 'p-button-blurred'}`}
              label={title}
            />
          ))}
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
});

const getSnapshot = (videoNode: HTMLVideoElement, width = 1920, height = 1080, cameraTimeOffset: number | null) => {
  let canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx?.drawImage(videoNode, 0, 0, canvas.width, canvas.height);

  // Draw time in screenshot
  if (cameraTimeOffset !== null) {
    ctx.font = '48px Arial';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'top';
    ctx.fillStyle = 'white';
    const text = convertTimeToLocaleString(Date.now() - cameraTimeOffset, 'ru-RU');
    const textWidth = ctx.measureText(text).width;
    const x = canvas.width - textWidth + 300;
    const y = 50;
    ctx.fillText(text, x, y);
  }

  let image = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.download = 'screenshot.png';
  link.href = image;
  link.click();
};

const Screenshot: FC<{videoRef: ControlPanelProps['videoRef']; store: IStore}> = observer(({videoRef, store}) => {
  const locale = useLocale();
  const [isFullscreen, setIsFullscreen] = useState(document.fullscreenElement !== null);
  const [loadingStatus, setLoadingStatus] = useState<'init' | 'loading' | 'error' | 'complete'>('init');
  const {cameraTimeOffset} = useCameraTimeOffset();

  const handleFullscreenChange = () => {
    setIsFullscreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);
  const handleCapture = useCallback(async () => {
    const videoNode = videoRef?.current;

    const {naturalSize} = store;

    if (!videoNode) {
      return;
    }
    try {
      setLoadingStatus('loading');

      getSnapshot(videoNode, naturalSize.width, naturalSize.height, cameraTimeOffset);
      setLoadingStatus('complete');
    } catch (error) {
      setLoadingStatus('error');
    }
  }, [videoRef, cameraTimeOffset, store]);
  return isFullscreen ? (
    <Button
      data-cy={e2eModule.E2E_ATTRIBUTES.screenshot}
      icon={loadingStatus === 'loading' ? 'mdi mdi-loading mdi-spin' : ''}
      label={locale.controls.saveScreenshot}
      className="p-button-outlined"
      onClick={handleCapture}
    />
  ) : (
    <div />
  );
});

const $selectStream = css`
  & {
    > svg path {
      opacity: 0.5;
    }

    &:hover {
      > svg path {
        opacity: 0.87;
      }
    }

    &[data-active='true'] {
      > svg path {
        opacity: 1;
      }
      &:hover {
        > svg path {
          opacity: 1;
        }
      }
    }
  }
`;
